import { Component, inject, OnInit } from "@angular/core";
import { ComponentBase } from "../../models/ComponentBase";
import { ActivatedRoute, Router } from "@angular/router";
import { StorageService } from "../../services/storage.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
    Storage_GetStorageItemListResponse,
    Storage_GetStorageItemResponse,
} from "../../WebApiClient";
import { distinctUntilChanged, map } from "rxjs/operators";
import {
    IconDefinition,
    faSortAmountDown,
    faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import { SortDirection, SortEvent } from "../../models/ngSortable";
import { FormControl, FormGroup } from "@angular/forms";

type StorageItemQueryKeys = "page" | "keyword" | "sort" | "direction";
type StorageItemQueryParams = {
    [k in StorageItemQueryKeys]?: string;
};

@Component({
    selector: "app-storage-items",
    templateUrl: "./storage-items.component.html",
    styleUrls: [
        "./storage-items.component.scss",
        "../../style/sidebarLayout.scss",
    ],
})
export class StorageItemsComponent extends ComponentBase implements OnInit {

    public readonly response: Observable<
        Storage_GetStorageItemListResponse | undefined
    >;
    public readonly storageItems: Observable<Storage_GetStorageItemResponse[] | undefined>;

    sortDirection: SortEvent | undefined = {
        column: "description",
        direction: "asc",
    };
    sortingBehaviorSubject = new BehaviorSubject<SortEvent>({
        column: "description",
        direction: "asc",
    });

    public selectedStorageItem: Observable<
    Storage_GetStorageItemResponse | undefined
    >;

    public readonly faBack: IconDefinition = faSortAmountDown;

    public readonly isPreviousPagePossible: Observable<boolean>;
    public readonly isNextPagePossible: Observable<boolean>;

    searchForm: FormGroup = new FormGroup({
        searchKeyword: new FormControl(""),
    });

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private readonly storageService: StorageService,
        private readonly router: Router
    ) {
        super();

        const queryParams =
            activatedRoute.queryParams as Observable<StorageItemQueryParams>;

        let pageObservable = queryParams.pipe(
            map((params) => {
                let newPage = Math.max(1, Number(params.page ?? 1));
                let keyword = params.keyword ?? "";
                let sort = params.sort ?? "description";
                let direction = params.direction ?? "asc";

                this.sortDirection = {
                    column: sort,
                    direction: direction as SortDirection,
                };

                return {
                    page: newPage,
                    keyword: keyword,
                    sort: this.sortDirection,
                };
            }),
            distinctUntilChanged(
                (a, b) => JSON.stringify(a) === JSON.stringify(b)
            )
        );

        this.response =
            this.storageService.getStorageItemsObservable(pageObservable);
        this.subscribeTo(this.response, (r) => console.log("response: ", r));

        this.isNextPagePossible = this.response.pipe(
            map((r) =>
                r &&
                r.page &&
                r.pageTotal &&
                Number(r.page) < Number(r.pageTotal)
                    ? true
                    : false
            )
        );
        this.isPreviousPagePossible = queryParams.pipe(
            map((qp) => (qp.page ? Number(qp.page) > 1 : false))
        );

        this.storageItems = this.response.pipe(map((r) => r?.storageItems));
        this.selectedStorageItem = of(undefined);
    }

    ngOnInit(): void {
        const keyword: string | undefined = this.activatedRoute.snapshot.queryParams.keyword;
        if(keyword) {
            this.searchForm.get("searchKeyword")?.setValue(keyword);
        }
    }

    getSortDirection(column: string): any {
        if (this.sortDirection && this.sortDirection.column === column) {
            return this.sortDirection.direction;
        }
        return "";
    }

    onSort({ column, direction }: SortEvent) {
        this.sortDirection = {
            column: column,
            direction: direction,
        };

        this.sortingBehaviorSubject.next({ column, direction });

        this.navigate({ sort: this.sortDirection });
    }

    getSortDirectionIcon(column: string): IconDefinition | undefined {
        if (this.sortDirection && this.sortDirection.column === column) {
            return this.sortDirection.direction === "asc"
                ? faSortAmountDown
                : faSortAmountUp;
        }
        return undefined;
    }

    nextPage() {
        if (!this.isNextPagePossible) return;
        this.navigate({ page: this.page + 1 });
    }

    previousPage() {
        if (!this.isPreviousPagePossible) return;
        this.navigate({ page: Math.max(this.page - 1, 1) });
    }

    onSubmit() {
        this.navigate({
            keyword:
                this.searchForm.value.searchKeyword === ""
                    ? null
                    : this.searchForm.value.searchKeyword,
            page: 0,
        });
    }

    onRowClick(storageItem: Storage_GetStorageItemResponse) {
        const qp2 = {
            ...this.activatedRoute.snapshot.queryParams,
        } as StorageItemQueryParams;

        this.router.navigate(["/storage/items", storageItem.id], {
            queryParams: qp2,
            relativeTo: this.activatedRoute,
            queryParamsHandling: "",
        });
        }

    public get page() {
        const p = this.activatedRoute.snapshot.queryParams["page"];
        return !!p ? Number(p) : 1;
    }

    private navigate(
        query: Partial<{
            page: number;
            keyword: string | null;
            sort: SortEvent | null;
        }>
    ) {
        const qp2 = {
            ...this.activatedRoute.snapshot.queryParams,
        } as StorageItemQueryParams;
        if (query.page !== undefined) {
            if (query.page <= 1) {
                delete qp2.page;
            } else {
                qp2.page = query.page.toString();
            }
        }

        if (query.keyword !== undefined) {
            if (query.keyword === null) {
                delete qp2.keyword;
            } else {
                qp2.keyword = query.keyword;
            }
        }

        if (query.sort !== undefined) {
            if (query.sort === null) {
                delete qp2.sort;
                delete qp2.direction;
            } else {
                qp2.sort = query.sort.column;
                qp2.direction = query.sort.direction;
            }
        }

        this.router.navigate([], {
            queryParams: qp2,
            relativeTo: this.activatedRoute,
            queryParamsHandling: "",
        });
    }
}
