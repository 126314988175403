import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren, Directive } from '@angular/core';
import { User_GetUserListResponse, User_User, UserRight, UserRole, Customer_Customer } from '../../WebApiClient';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { LoginService } from '../../services/login.service';
import { ComponentBase } from '../../models/ComponentBase';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { UserRoles } from '../../Constants';
import { CustomerService } from '../../services/customer.service';
import { NgbdSortableHeader, SortColumn, SortDirection, SortEvent } from '../../models/ngSortable';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSortUp, faSortDown, IconDefinition, faSortAmountDown, faSortAmountUp } from '@fortawesome/free-solid-svg-icons';

type UsersQueryKeys = "page" | "showActive" | "showInactive" | "user";
type UsersQueryParams = {
    [k in UsersQueryKeys]?: string
};

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss', '../../style/sidebarLayout.scss']
})
export class UsersComponent extends ComponentBase implements OnInit {

    @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader> | undefined;

    public readonly response: Observable<User_GetUserListResponse | undefined>;
    public readonly selectedUser: Observable<User_User | undefined>;
    public readonly isNextPagePossible: Observable<boolean>;
    public readonly isPreviousPagePossible: Observable<boolean>;
    public readonly users: Observable<User_User[] | undefined>;
    public readonly showNewUser = this.route.queryParams.pipe(map(qp => qp["user"] === "new"))
    public readonly showActive = this.route.queryParams.pipe(map((qp: UsersQueryParams) => {
        return qp.showActive === "true";
    }));
    public readonly showInactive = this.route.queryParams.pipe(map((qp: UsersQueryParams) => {
        return qp.showInactive === "true";
    }));

    public readonly showEditButtons: boolean;
    public readonly showEditor: boolean = false;

    public searchForm: FormGroup = new FormGroup({
        searchKeyword: new FormControl(""),
        searchCustomer: new FormControl(""),
        searchUserRole: new FormControl("")
    });

    readonly roles: { key: UserRole, displayName: string }[];
    customers: Customer_Customer[] | undefined;

    sortDirection: SortEvent | undefined = { column: "name", direction: "asc" };
    sortingBehaviorSubject = new BehaviorSubject<SortEvent>({ column: "name", direction: "asc" });

    constructor(
        private readonly route: ActivatedRoute,
        userService: UserService,
        private readonly router: Router,
        private readonly loginService: LoginService,
        private readonly customerService: CustomerService
    ) {
        super();
        this.showEditButtons = loginService.hasRights(UserRight.EditUser);

        this.roles = UserRoles;

        const queryParams = route.queryParams as Observable<UsersQueryParams>;

        let pageObservable = queryParams.pipe(
            map(params => {
                let newPage = Math.max(1, Number(params.page ?? 1));
                return {
                    page: newPage,
                    showActive: params.showActive === "true",
                    showInactive: params.showInactive === "true",
                };
            }),
            distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
        );

        let searchKeywordObservable = this.searchKeywordControl.valueChanges
            .pipe(
                debounceTime(250),
                map((v) => {
                    return v;
                })
            );

        let searchUserRoleObservable = this.searchUserRoleControl.valueChanges
            .pipe(
                map((v) => {
                    return v;
                })
            );

        let searchCustomerObservable = this.searchCustomerControl.valueChanges
            .pipe(
                map((v) => {
                    return v;
                })
            );

        let sortingObservable = this.sortingBehaviorSubject;

        this.response = userService.getUsersObservable(pageObservable, searchKeywordObservable, searchUserRoleObservable, searchCustomerObservable, sortingObservable);
        this.subscribeTo(this.response, r => console.log("response: ", r))

        this.users = this.response.pipe(map(r => r?.users));

        this.selectedUser = combineLatest([
            queryParams,
            this.response
        ]).pipe(map(([queryParams, response]) => {
            const userId = queryParams.user;
            return response?.users?.find(u => u.id === userId);
        }))

        this.subscribeTo(this.selectedUser, u => console.log("user selection", u))

        this.isNextPagePossible = this.response.pipe(map(r => r?.isFullPage ?? false));
        this.isPreviousPagePossible = queryParams.pipe(map(qp => qp.page ? Number(qp.page) > 1 : false));

        let cusSub = customerService.customersObservable.subscribe(cs => {
            this.customers = cs;
        });
        this.subscriptions.push(cusSub);
    }

    ngOnInit() {
        this.searchKeywordControl.setValue("");
        this.searchUserRoleControl.setValue("");
        this.searchCustomerControl.setValue("");
    }

    public roleName(user: User_User) {
        return user.userRole;
    }

    private get page() {
        const p = this.route.snapshot.queryParams["page"];
        return (!!p) ? Number(p) : 1;
    }

    public get searchKeywordControl(): FormControl {
        return this.searchForm.controls["searchKeyword"] as FormControl;
    }

    public get searchUserRoleControl(): FormControl {
        return this.searchForm.controls["searchUserRole"] as FormControl;
    }

    public get searchCustomerControl(): FormControl {
        return this.searchForm.controls["searchCustomer"] as FormControl;
    }

    public get showNewUserButton() {
        return this.loginService.hasRights(UserRight.EditUser);
    }

    public editUser(id: string) {
        this.navigate({ user: id })
    }

    public startNewUser() {
        if (!this.loginService.hasRights(UserRight.EditUser))
            return;
        this.navigate({ user: "new" })
    }

    public nextPage() {
        if (!this.isNextPagePossible)
            return;
        this.navigate({ page: this.page + 1, user: null });
    }


    public previousPage() {
        if (!this.isPreviousPagePossible)
            return;
        this.navigate({ page: Math.max(this.page - 1, 1), user: null })
    }

    public setShowActive(value: boolean) {
        this.navigate({ showActive: value, user: null })
    }

    public setShowInactive(value: boolean) {
        this.navigate({ showInactive: value, user: null })
    }

    public isActive(user: User_User) {
        if (!user.lastLogin)
            return false;
        const durationMs = Date.now() - user.lastLogin.valueOf();

        const msIn1Day = (1000 * 60 * 60 * 24);
        const durationDays = durationMs / msIn1Day;

        return durationDays < 30 * 3;
    }

    public getSortDirection(column: string): string {
        if (this.sortDirection && this.sortDirection.column === column) {
            return this.sortDirection.direction;
        }
        return "";
    }

    public getSortDirectionIcon(column: string): IconDefinition | undefined {
        if (this.sortDirection && this.sortDirection.column === column) {
            return this.sortDirection.direction === "asc" ? faSortAmountDown : faSortAmountUp;
        }
        return undefined;
    }

    public onSort({ column, direction }: SortEvent) {
        this.sortDirection = {
            column: column,
            direction: direction
        };

        this.sortingBehaviorSubject.next({ column, direction });
    }

    private navigate(query: Partial<{
        page: number,
        showActive: boolean,
        showInactive: boolean,
        user: string | null,

    }>) {

        const qp2 = { ...this.route.snapshot.queryParams } as UsersQueryParams;
        if (query.page !== undefined) {
            if (query.page <= 1) {
                delete qp2.page;
            }
            else {
                qp2.page = query.page.toString();
            }
        }
        if (query.showActive !== undefined) {
            qp2.showActive = query.showActive.toString();
        }
        if (query.showInactive !== undefined) {
            qp2.showInactive = query.showInactive.toString();
        }
        if (query.user !== undefined) {
            if (query.user === null) {
                delete qp2.user;
            }
            else {
                qp2.user = query.user;
            }
        }

        this.router.navigate([], {
            queryParams: qp2,
            relativeTo: this.route,
            queryParamsHandling: ''
        });
    }
}
