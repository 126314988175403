import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, merge, Observable, of } from "rxjs";
import {
    Storage_GetStorageItemListRequest,
    Storage_GetStorageItemListResponse,
    Storage_GetStorageItemResponse,
    Storage_GetStorageItemMovementListResponse,
    Storage_GetStorageItemMovementListRequest,
    StorageClient,
    Storage_GetStorageItemMovementQuantityListResponse_Quantity,
    Storage_GetStorageItemBinListResponse,
    Storage_GetStorageItemBinListRequest,
    Storage_GetStorageTankListResponse,
    Storage_GetStorageTankListRequest,
    Storage_StorageTank,
    Storage_GetStorageTankFilesResponse,
    FileResponse,
} from "../WebApiClient";
import {
    distinctUntilChanged,
    map,
    publishReplay,
    refCount,
    switchMap,
} from "rxjs/operators";
import { SortEvent } from "../models/ngSortable";

@Injectable()
export class StorageService {
    private trigger = new BehaviorSubject(true);

    public constructor(private readonly storageClient: StorageClient) {}

    public getStorageItemsObservable(
        pageObservable: Observable<{
            page: number;
            keyword: string;
            sort: SortEvent;
        }>
    ): Observable<Storage_GetStorageItemListResponse | undefined> {
        let maxResultsPerPage = 25;
        let o = combineLatest(
            pageObservable.pipe(distinctUntilChanged()),
            this.trigger
        ).pipe(
            map((x) => ({ shouldResolve: x[1], request: x[0] })),
            switchMap((x) =>
                x.shouldResolve
                    ? merge(
                          of(undefined),
                          this.storageClient.getStorageItemList(
                              new Storage_GetStorageItemListRequest({
                                  page: x.request.page,
                                  keyword: x.request.keyword,
                                  sortColumn: x.request.sort.column,
                                  sortDirection: x.request.sort.direction,
                              })
                          )
                      )
                    : of(undefined)
            ),
            publishReplay(2),
            refCount()
        );

        return o;
    }

    public getStorageItemObservable(
        id: string
    ): Observable<Storage_GetStorageItemResponse | undefined> {
        return this.storageClient.getStorageItem(id);
    }

    public getStorageItemMovementsObservable(
        pageObservable: Observable<{
            id: string;
            page: number;
            sort: SortEvent;
        }>
    ): Observable<Storage_GetStorageItemMovementListResponse | undefined> {
        let maxResultsPerPage = 25;
        let o = combineLatest(
            pageObservable.pipe(distinctUntilChanged()),
            this.trigger
        ).pipe(
            map((x) => ({ shouldResolve: x[1], request: x[0] })),
            switchMap((x) =>
                x.shouldResolve && x.request.id
                    ? merge(
                          of(undefined),
                          this.storageClient.getStorageItemMovementList(
                              new Storage_GetStorageItemMovementListRequest({
                                  page: x.request.page,
                                  sortColumn: x.request.sort.column,
                                  sortDirection: x.request.sort.direction,
                              }),
                              x.request.id
                          )
                      )
                    : of(undefined)
            ),
            publishReplay(2),
            refCount()
        );

        return o;
    }

    public getStorageItemMovementQuantityObservable(
        id: string,
        year: number
    ): Observable<
        | Storage_GetStorageItemMovementQuantityListResponse_Quantity[]
        | undefined
    > {
        return this.storageClient
            .getStorageItemMovementQuantityList(id, year)
            .pipe(map((x) => x.quantityList));
    }

    public getStorageItemBinsObservable(
        pageObservable: Observable<{
            id: string;
            page: number;
            sort: SortEvent;
        }>
    ): Observable<Storage_GetStorageItemBinListResponse | undefined> {
        let maxResultsPerPage = 25;
        let o = combineLatest(
            pageObservable.pipe(distinctUntilChanged()),
            this.trigger
        ).pipe(
            map((x) => ({ shouldResolve: x[1], request: x[0] })),
            switchMap((x) =>
                x.shouldResolve && x.request.id
                    ? merge(
                          of(undefined),
                          this.storageClient.getStorageItemBinList(
                              new Storage_GetStorageItemBinListRequest({
                                  page: x.request.page,
                                  sortColumn: x.request.sort.column,
                                  sortDirection: x.request.sort.direction,
                              }),
                              x.request.id
                          )
                      )
                    : of(undefined)
            ),
            publishReplay(2),
            refCount()
        );

        return o;
    }

    public getStorageTanksObservable(
        pageObservable: Observable<{
            id: string;
            page: number;
            sort: SortEvent;
        }>
    ): Observable<Storage_GetStorageTankListResponse | undefined> {
        let maxResultsPerPage = 25;
        let o = combineLatest(
            pageObservable.pipe(distinctUntilChanged()),
            this.trigger
        ).pipe(
            map((x) => ({ shouldResolve: x[1], request: x[0] })),
            switchMap((x) =>
                x.shouldResolve && x.request.id
                    ? merge(
                          of(undefined),
                          this.storageClient.getStorageTankList(
                              new Storage_GetStorageTankListRequest({
                                  page: x.request.page,
                                  sortColumn: x.request.sort.column,
                                  sortDirection: x.request.sort.direction,
                              }),
                              x.request.id
                          )
                      )
                    : of(undefined)
            ),
            publishReplay(2),
            refCount()
        );

        return o;
    }

    public getStorageTankFilesObectObservable(
        itemId: string,
        tankId: string
    ): Observable<Storage_GetStorageTankFilesResponse | undefined> {
        return this.storageClient.getStorageTankFiles(itemId, tankId);
    }

    public async downloadFile(itemId: string, tankId: string, fileName: string) {
        let pdf = await this.storageClient
            .getStorageTankFile(itemId, tankId, fileName)
            .toPromise();

        this.download2(pdf);
    }

    private download2(file: FileResponse) {
        if(file.fileName == null || file.fileName == undefined){
            return;
        }

        let url = window.URL.createObjectURL(file.data);
        let a = document.createElement("a");

        a.href = url;
        a.download = file.fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
    }
}
