import { OnDestroy, Directive } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive()
export class ComponentBase implements OnDestroy {

    protected readonly subscriptions: Subscription[] = [];

    protected subscribeTo<T>(observable: Observable<T>, action: ((t: T) => any)) {
        this.subscriptions.push(observable.subscribe(action));
    }

    ngOnDestroy() {
        for (let sub of this.subscriptions)
            sub.unsubscribe();
    }

    loadStatus(list?: any[]): "loading" | "empty" | "loaded" {
        if (!list)
            return "loading";
        if (list.length === 0)
            return "empty";
        return "loaded";
    }

    isLoading(list?: any[]): list is undefined { return !list; }
    isEmpty(list?: any[]): boolean { return list?.length === 0; }
    isLoaded(list?: any[]): boolean { return (list?.length ?? 0) > 0; }
    
    mapObservable<Tin, Tout>(observable: Observable<Tin>, func: (val: Tin) => Tout): Observable<Tout> {
        return observable.pipe(map(func))
    }
}
