<app-topbar></app-topbar>

<nav class="sidebar navbar-dark bg-dark">

</nav>
<ng-container>
    <div class="main-content">
        <a routerLink="/storage/items" class="btn btn-primary">Storage Items</a>
    </div>

</ng-container>
