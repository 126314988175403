<div *ngIf="mapObservable(storageItemMovements, isLoading) | async">
    Loading...
</div>
<div *ngIf="mapObservable(storageItemMovements, isEmpty) | async">
    No Movements yet.
</div>
<div *ngIf="mapObservable(storageItemMovements, isLoaded) | async">
<table class="table table-striped table-hover">
    <colgroup>
        <col style="width: auto" />
    </colgroup>

    <thead>
        <tr>
            <th
                scope="col"
                sortable="postingDate"
                [direction]="getSortDirection('postingDate')"
                (sort)="onSort($event)"
            >
                Date
                <fa-icon
                    *ngIf="
                        getSortDirection('postingDate');
                        else sortNone
                    "
                    [icon]="getSortDirectionIcon('postingDate')"
                ></fa-icon>
            </th>
            <th
                scope="col"
                sortable="description"
                [direction]="getSortDirection('description')"
                (sort)="onSort($event)"
            >
                Description
                <fa-icon
                    *ngIf="
                        getSortDirection('description');
                        else sortNone
                    "
                    [icon]="getSortDirectionIcon('description')"
                ></fa-icon>
            </th>
            <th
                scope="col"
                scope="col"
                sortable="quantity"
                [direction]="getSortDirection('quantity')"
                (sort)="onSort($event)"
            >
                Quantity
                <fa-icon
                    *ngIf="
                        getSortDirection('quantity');
                        else sortNone
                    "
                    [icon]="getSortDirectionIcon('quantity')"
                ></fa-icon>
            </th>

            <th
                scope="col"
                sortable="lotNo"
                [direction]="getSortDirection('lotNo')"
                (sort)="onSort($event)"
            >
                Location No
                <fa-icon
                    *ngIf="getSortDirection('lotNo'); else sortNone"
                    [icon]="getSortDirectionIcon('lotNo')"
                ></fa-icon>
            </th>
            <th
                scope="col"
                sortable="externalId"
                [direction]="getSortDirection('externalId')"
                (sort)="onSort($event)"
            >
                External Id
                <fa-icon
                    *ngIf="
                        getSortDirection('externalId');
                        else sortNone
                    "
                    [icon]="getSortDirectionIcon('externalId')"
                ></fa-icon>
            </th>
        </tr>
    </thead>

    <tbody>
        <tr
            *ngFor="
                let storageItemMovement of storageItemMovements
                    | async
            "
            class="storageitemrow"
        >
            <td>
                {{
                    storageItemMovement.postingDate
                        | date : "medium"
                }}
            </td>
            <td>{{ storageItemMovement.description }}</td>
            <td>{{ storageItemMovement.quantity }}</td>
            <td>{{ storageItemMovement.lotNo }}</td>
            <td>{{ storageItemMovement.externalId }}</td>
        </tr>
    </tbody>
</table>
<div>
    <div class="btn-group btn-group-sm">
        <button
            class="btn btn-light"
            type="button"
            [disabled]="!(isPreviousPagePossible | async)"
            (click)="previousPage()"
        >
            &laquo; Prev
        </button>
        <button
            class="btn btn-light"
            type="button"
            [disabled]="!(isNextPagePossible | async)"
            (click)="nextPage()"
        >
            Next &raquo;
        </button>
    </div>
</div>
</div>
<ng-template #sortNone>
    <div style="width: 16px; height: 21px; display: inline-block"></div>
</ng-template>
