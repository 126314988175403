<div *ngIf="mapObservable(storageTanks, isLoading) | async">Loading...</div>
<div *ngIf="mapObservable(storageTanks, isEmpty) | async">No Bins yet.</div>
<div *ngIf="mapObservable(storageTanks, isLoaded) | async">
    <table class="table table-striped table-hover">
        <colgroup>
            <col style="width: auto" />
        </colgroup>

        <thead>
            <tr>
                <th
                    scope="col"
                    sortable="externalId"
                    [direction]="getSortDirection('externalId')"
                    (sort)="onSort($event)"
                >
                    External Id
                    <fa-icon
                        *ngIf="getSortDirection('externalId'); else sortNone"
                        [icon]="getSortDirectionIcon('externalId')"
                    ></fa-icon>
                </th>
                <th
                    scope="col"
                    sortable="location"
                    [direction]="getSortDirection('location')"
                    (sort)="onSort($event)"
                >
                    Location
                    <fa-icon
                        *ngIf="getSortDirection('location'); else sortNone"
                        [icon]="getSortDirectionIcon('location')"
                    ></fa-icon>
                </th>
                <th
                    scope="col"
                    scope="col"
                    sortable="testdate"
                    [direction]="getSortDirection('testdate')"
                    (sort)="onSort($event)"
                >
                    Test date
                    <fa-icon
                        *ngIf="getSortDirection('testdate'); else sortNone"
                        [icon]="getSortDirectionIcon('testdate')"
                    ></fa-icon>
                </th>
            </tr>
        </thead>

        <tbody>
            <ng-template ngFor let-storageTank [ngForOf]="storageTanks | async">
                <tr
                    class="storageitemrow"
                    (click)="selectTankToggle(storageTank)"
                >
                    <td class="external-id">
                        <fa-icon
                            [icon]="faPlus"
                            *ngIf="storageTank !== storageTankSelected"
                        ></fa-icon>
                        <fa-icon
                            [icon]="faMinus"
                            *ngIf="storageTank === storageTankSelected"
                        ></fa-icon>
                        <div>{{ storageTank.externalId }}</div>
                    </td>
                    <td>{{ storageTank.location }}</td>
                    <td>{{ storageTank.testDate | date : "medium" }}</td>
                </tr>
                <ng-template [ngIf]="storageTank === storageTankSelected">
                    <tr class="storageTankSelected">
                        <td colspan="3">
                            <table
                                class="table table-striped table-hover"
                                *ngIf="storageTankFiles && !isStorageTankFilesLoading"
                            >
                                <colgroup>
                                    <col style="width: auto" />
                                </colgroup>
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Date modified</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let file of storageTankFiles">
                                        <td class="download-button" (click)="downloadFile(file)">
                                            <fa-icon
                                                [icon]="faDownload"
                                            ></fa-icon>
                                            {{ file.fileName }}
                                        </td>
                                        <td>
                                            {{
                                                file.dateModified
                                                    | date : "medium"
                                            }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div *ngIf="!storageTankFiles && !isStorageTankFilesLoading">
                                No files found
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </ng-template>
        </tbody>
    </table>
    <div>
        <div class="btn-group btn-group-sm">
            <button
                class="btn btn-light"
                type="button"
                [disabled]="!(isPreviousPagePossible | async)"
                (click)="previousPage()"
            >
                &laquo; Prev
            </button>
            <button
                class="btn btn-light"
                type="button"
                [disabled]="!(isNextPagePossible | async)"
                (click)="nextPage()"
            >
                Next &raquo;
            </button>
        </div>
    </div>
</div>
<ng-template #sortNone>
    <div style="width: 16px; height: 21px; display: inline-block"></div>
</ng-template>
