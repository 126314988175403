import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "../../services/login.service";
import { UserRight } from "../../WebApiClient";


@Component({
    selector: "app-front-page",
    templateUrl: "./front-page.component.html",
    styleUrls: ["./front-page.component.scss", '../../style/sidebarLayout.scss'],
})
export class FrontPageComponent implements OnInit {
    constructor(
        private readonly loginService: LoginService,
        //private readonly router: Router
    ) {

    }

    public get showShippingOperations() {
        return this.loginService.hasRights(UserRight.ViewShippingOperations);
    }
    public get showUserAdmin() {
        return this.loginService.hasRights(UserRight.ViewUserAdmin);
    }
    public get showStorage() {
        return this.loginService.hasRights(UserRight.ViewStorage);
    }
    public get showSustainability() {
        return this.loginService.hasRights(UserRight.ViewSustainability);
    }
    public get showTerminal() {
        return this.loginService.hasRights(UserRight.ViewTerminalPage);
    }

    ngOnInit(): void {}
}
