import { Injectable } from '@angular/core';
import { UserClient, User_GetUserListResponse, User_UpsertUserRequest, User_DeleteUserRequest, User_GetUserListRequest, User_RestoreUserRequest } from '../WebApiClient';
import { Observable, BehaviorSubject, merge, of, combineLatest } from 'rxjs';
import { switchMap, distinctUntilChanged, map, publishReplay, refCount, distinctUntilKeyChanged } from 'rxjs/operators';
import { SortEvent } from '../models/ngSortable';

@Injectable()
export class UserService {

    private trigger = new BehaviorSubject(true);

    public constructor(
        private readonly userClient: UserClient
    ) {

    }

    public getUsersObservable(pageObservable: Observable<{
        page: number;
        showActive: boolean;
        showInactive: boolean;
    }>, keywordObservable: Observable<string>, userRoleObservable: Observable<string>, customerObservable: Observable<string>, sortingObservable: Observable<SortEvent>): Observable<User_GetUserListResponse | undefined> {
        let o = combineLatest(pageObservable.pipe(distinctUntilChanged()), keywordObservable.pipe(distinctUntilChanged()), userRoleObservable.pipe(distinctUntilChanged()), customerObservable.pipe(distinctUntilChanged()), sortingObservable.pipe(distinctUntilChanged()), this.trigger).pipe(
            map(x => ({ shouldResolve: x[5], request: x[0], keyword: x[1], userRole: x[2], customer: x[3], sorting: x[4] })),
            switchMap(x => x.shouldResolve ? merge(
                of(undefined), //return undefined while the list is refreshing
                this.userClient.getUserList(new User_GetUserListRequest({
                    customer: x.customer,
                    keyword: x.keyword,
                    page: x.request.page,
                    showActive: x.request.showActive,
                    showInactive: x.request.showInactive,
                    userRole: x.userRole,
                    sortColumn: x.sorting.column,
                    sortDirection: x.sorting.direction
                }))
            ) : of(undefined)),
            publishReplay(2),
            refCount(),
        );
        return o;
    }

    public upsertUser(user: User_UpsertUserRequest) {
        this.trigger.next(false);
        let observable = this.userClient.upsertUser(user).pipe(
            publishReplay(2),
            refCount()
        );
        observable.toPromise().then(_ => this.trigger.next(true));
        return observable;
    }

    public deleteUser(userId: string) {
        this.trigger.next(false);
        let o = this.userClient.deleteUser(new User_DeleteUserRequest({ id: userId })).pipe(
            publishReplay(2),
            refCount()
        );
        o.toPromise().then(_ => this.trigger.next(true));
        return o;
    }

    public restoreUser(userId: string) {
        this.trigger.next(false);
        let o = this.userClient.restoreUser(new User_RestoreUserRequest({ id: userId })).pipe(
            publishReplay(2),
            refCount()
        );
        o.toPromise().then(_ => this.trigger.next(true));
        return o;
    }
}
