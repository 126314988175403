import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./pages/login/login.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { ProjectsComponent } from "./pages/projects/projects.component";
import { HasRightsGuard } from "./guards/has-rights.guard";
import { UserRight } from "./WebApiClient";
import { UsersComponent } from "./pages/users/users.component";
import { TimelineComponent as ProjectTimelineComponent } from "./pages/project-details/timeline/timeline.component";
import { ComponentsComponent as ProjectComponentsComponent } from "./pages/project-details/components/components.component";
import { ReportsComponent as ProjectReportsComponent } from "./pages/project-details/reports/reports.component";
import { ProjectOverviewComponent } from "./pages/project-details/project-overview/project-overview.component";
import { GangsComponent } from "./pages/project-details/gangs/gangs.component";
import { FilesComponent } from "./pages/project-details/files/files.component";
import { LinkComponent } from "./pages/link/link.component";
import { LinksComponent } from "./pages/project-details/links/links.component";
import { UnsubscribeComponent } from "./pages/unsubscribe/unsubscribe.component";
import { MailRecipientComponent } from "./pages/project-details/mail-recipient/mail-recipient.component";
import { CreateProjectComponent } from "./pages/create-project/create-project.component";
import { EditProjectComponent } from "./pages/edit-project/edit-project.component";
import { ProjectDocumentsComponent } from "./pages/project-details/project-documents/project-document.component";
import { DonwloadProjectDocumentsComponent } from "./pages/download-project-documents/download-project-documents";
import { CompletedProjectsComponent } from "./pages/completed-projects/completed-projects.component";
import { CompletedProjectQuestionnaireComponent } from "./pages/completed-project-questionnaire/completed-project-questionnaire.component";
import { ProjectReportAndStastiticsComponent } from "./pages/project-details/project-report/project-report.component";
import { MailLogComponent } from "./pages/project-details/mail-log/mail-log.component";
import { DailyComponent } from "./pages/unsub/daily/daily.component";
import { FrontPageComponent } from "./pages/front-page/front-page.component";
import { StorageComponent } from "./pages/storage/storage.component";
import { StorageItemsComponent } from "./pages/storage-items/storage-items.component";
import { StorageItemDetailsComponent } from "./pages/storage-item-details/storage-item-details.component";

const routes: Routes = [
    { path: "login", component: LoginComponent },
    { path: "link/:id", component: LinkComponent },
    {
        path: "projects/new",
        component: CreateProjectComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.EditProject] },
    },
    {
        path: "projects",
        component: ProjectsComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ListOwnProjects] },
    },
    {
        path: "completedProjects",
        component: CompletedProjectsComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ListOwnProjects] },
    },
    {
        path: "users",
        component: UsersComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ListUsers] },
    },
    { path: "unsubscribe/:id", component: UnsubscribeComponent },
    { path: "unsub/daily/:id", component: DailyComponent },
    {
        path: "projects/:id/edit",
        component: EditProjectComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.EditProject] },
    },
    {
        path: "projects/:id",
        pathMatch: "full",
        redirectTo: "projects/:id/overview",
    },
    {
        path: "projects/:id/overview",
        component: ProjectOverviewComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ListOwnProjects] },
    },
    {
        path: "projects/:id/timeline",
        component: ProjectTimelineComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ListOwnProjects] },
    },
    {
        path: "projects/:id/components",
        component: ProjectComponentsComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ListOwnProjects] },
    },
    {
        path: "projects/:id/gangs",
        component: GangsComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ListOwnProjects] },
    },
    {
        path: "projects/:id/reports",
        component: ProjectReportsComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.GetProjectReportsForOwnProjects] },
    },
    {
        path: "projects/:id/files",
        component: FilesComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.DownloadProjectFilesOnOwnProject] },
    },
    {
        path: "projects/:id/mail",
        component: MailRecipientComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.GetMailingList] },
    },
    {
        path: "projects/:id/links",
        component: LinksComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.CreateLinkForCustomerProject] },
    },
    {
        path: "projects/:id/projectDocuments",
        component: ProjectDocumentsComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.DownloadProjectFilesOnOwnProject] },
    },
    {
        path: "projects/:id/downloadProjectDocuments",
        component: DonwloadProjectDocumentsComponent,
    },
    {
        path: "questionnaire/:qid",
        component: CompletedProjectQuestionnaireComponent,
    },
    {
        path: "projects/:id/projectreport",
        component: ProjectReportAndStastiticsComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ListOwnProjects] },
    },
    {
        path: "projects/:id/maillog",
        component: MailLogComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ListOwnProjects] },
    },
    {
        path: "storage",
        redirectTo: "storage/items", // TODO: remove this redirect
        pathMatch: "full",
        //component: StorageComponent,
        //canActivate: [HasRightsGuard],
        //data: { rights: [UserRight.ViewStorage] },
    },
    {
        path: "storage/items",
        component: StorageItemsComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ViewStorage] },
    },
    {
        path: "storage/items/:id",
        component: StorageItemDetailsComponent,
        canActivate: [HasRightsGuard],
        data: { rights: [UserRight.ViewStorage] },
    },
    {
        path: "",
        pathMatch: "full" ,
        component: FrontPageComponent,
        canActivate: [HasRightsGuard],
        data: {rights: [UserRight.ViewFrontPage]},
    },
    { path: "**", component: PageNotFoundComponent },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
