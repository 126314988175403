import { Component, } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { ProjectService } from '../../services/project.service';
import { Project_GetProjectDetailsResponse, UserRight } from '../../WebApiClient';
import { LoginService } from '../../services/login.service';
import { ComponentBase } from '../../models/ComponentBase';

@Component({
    selector: 'app-project-details',
    templateUrl: './project-details.component.html',
    styleUrls: ['./project-details.component.scss' /*, '../../style/info-group.scss'*/]
})
export class ProjectDetailsComponent extends ComponentBase {

    project: Project_GetProjectDetailsResponse | undefined = undefined;

    readonly showReports: boolean;
    readonly showFiles: boolean;
    readonly showGangs: boolean;
    readonly showMail: boolean;
    readonly showLinks: boolean;
    readonly showEdit: boolean;

    constructor(
        route: ActivatedRoute,
        private readonly projectService: ProjectService,
        private readonly loginService: LoginService,
    ) {
        super();
        let projectSubscription = route.params.pipe(
            map(params => params["id"]),
            switchMap(id => projectService.getProjectDetails(id))
        ).subscribe(proj => this.project = proj);

        this.subscriptions.push(projectSubscription);

        this.showReports = this.loginService.hasRights(UserRight.ListProjectTemplates);
        this.showFiles = this.loginService.hasRights(UserRight.DownloadProjectFilesOnOwnProject);
        this.showGangs = this.loginService.hasRights(UserRight.ListAllWorkers);
        this.showMail = this.loginService.hasRights(UserRight.GetMailingList);
        this.showLinks = this.loginService.hasRights(UserRight.CreateLinkForCustomerProject);
        this.showEdit = this.loginService.hasRights(UserRight.EditProject);
    }

    get projectName() {
        return this.project?.name ?? "Loading...";
    }

    reloadProject() {
        if (this.project) {
            this.projectService.reloadProject(this.project.id)
        }
        else {
            this.projectService.reloadProject();
        }
    }


}
