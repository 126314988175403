import { Component, Input } from '@angular/core';
import { ComponentBase } from '../../models/ComponentBase';
import { Customer_UpsertCustomerRequest } from '../../WebApiClient';

@Component({
    selector: 'app-edit-customer-inner',
    templateUrl: './edit-customer-inner.component.html',
    styleUrls: ['./edit-customer-inner.component.scss']
})
export class EditCustomerInnerComponent extends ComponentBase {

    constructor() { super() }


    @Input()
    customer: Customer_UpsertCustomerRequest = new Customer_UpsertCustomerRequest();

}
