<form (ngSubmit)="create()">
    <div class="card">
        <div class="card-header">
            <h3>Create Customer</h3>
        </div>
        <div class="card-body">

            <app-edit-customer-inner [customer]="customer"></app-edit-customer-inner>

        </div>
        <div class="card-footer">
            <button class="btn btn-block btn-primary"
                    [disabled]="!isInputValid">
                Create Customer
            </button>

        </div>
    </div>
</form>
