import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Customer_Customer, Customer_UpsertCustomerRequest } from '../../WebApiClient';
import { CustomerService } from '../../services/customer.service';

@Component({
    selector: 'app-edit-customer',
    templateUrl: './edit-customer.component.html',
    styleUrls: ['./edit-customer.component.scss', '../../style/crudFloat.scss']
})
export class EditCustomerComponent implements OnInit, OnChanges {

    public customer?: Customer_UpsertCustomerRequest;

    @Input("customer")
    customerToEdit?: Customer_Customer;

    constructor(
        private readonly customerService: CustomerService
    ) {
        this.resetCustomer();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ("customerToEdit" in changes)
            this.resetCustomer();
    }

    private resetCustomer() {
        if (!this.customerToEdit)
            return;
        this.customer = new Customer_UpsertCustomerRequest({
            id: this.customerToEdit.id,
            name: this.customerToEdit.name
        });
    }

    ngOnInit(): void {
        this.resetCustomer();
    }

    get isInputValid(): boolean {
        return this.customerService.isValid(this.customer);
    }

    async update() {
        if (!this.isInputValid)
            return;
        if (!this.customer)
            return;
        await this.customerService.upsert(this.customer).toPromise();
        this.resetCustomer();
    }

    async delete() {
        if (!this.customer)
            return;
        let isSure = confirm("Are you sure you want to delete this customer?")
        if (!isSure)
            return;
        await this.customerService.delete(this.customer.id).toPromise();
        this.resetCustomer();
    }
}
