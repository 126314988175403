import { Component } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { UserRight } from '../../WebApiClient';
import { ComponentBase } from '../../models/ComponentBase';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent extends ComponentBase {

    readonly showProjects: boolean;
    readonly showUsers: boolean;
    readonly showStorage: boolean;
    readonly showSustainability: boolean;
    readonly showTerminal: boolean;

    constructor(
        private readonly loginService: LoginService,
        private readonly router: Router,
    ) {
        super();
        this.showProjects = loginService.hasRights(UserRight.ViewShippingOperations);
        this.showUsers = loginService.hasRights(UserRight.ViewUserAdmin);
        this.showStorage = loginService.hasRights(UserRight.ViewStorage);
        this.showSustainability = loginService.hasRights(UserRight.ViewSustainability);
        this.showTerminal = loginService.hasRights(UserRight.ViewTerminalPage);

    }


    logout() {
        this.loginService.logout();
        this.router.navigate(["/login"]);
    }


}
