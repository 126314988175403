<app-topbar></app-topbar>

<nav class="sidebar navbar-dark bg-dark">
    <!-- Nothing to show here for now -->
</nav>

<div class="main-content">
    <div class="tile-box">
        <app-tile
            *ngIf="showShippingOperations"
            text="Shipping Operations"
            icon="../../assets/stevedoring.svg"
            link="/projects"
        ></app-tile>

        <app-tile
            *ngIf="showStorage"
            text="Storage Operations"
            icon="../../assets/logistik.svg"
            link="/storage"
        ></app-tile>

        <app-tile
            *ngIf="showSustainability"
            text="Sustainability"
            icon="../../assets/transport.svg"
            link="/sustainability"
        ></app-tile>

        <app-tile
            *ngIf="showTerminal"
            text="Terminal"
            icon="../../assets/terminal.svg"
            link="/terminal"
        ></app-tile>

        <app-tile
            *ngIf="showUserAdmin"
            text="User Administration"
            icon="../../assets/agency.svg"
            link="/users"
        ></app-tile>
    </div>
</div>
