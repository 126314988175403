<form (ngSubmit)="create()" autocomplete="off">

    <div class="card">
        <div class="card-header">

            <h3>Create User</h3>
        </div>
        <div class="card-body">
            <app-edit-user-inner [user]="user" (formIsValid)="formIsValid($event)" newUser="true"></app-edit-user-inner>

        </div>
        <div class="card-footer">
            <div class="btn-group float-right">
                <button class="btn btn-primary"
                        [disabled]="!inputValid">
                    Create User
                </button>
                <button class="btn btn-light" (click)="cancel()">
                    Cancel
                </button>
            </div>
        </div>
    </div>

</form>
