import {
    Component,
    HostListener,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
} from "@angular/core";
import Chart from "chart.js/auto";
import { ChartJsData } from "../../../models/ChartJsDataset";

@Component({
    selector: "app-bar-chart",
    template: `
        <div
            class="chart-container"
            style="position: relative; height:60vh; width:80vw"
        >
            <canvas id="BarChart">{{ chart }}</canvas>
        </div>
    `,
    styles: [],
})
export class BarChartComponent implements OnInit, OnChanges {
    @HostListener("window:beforeprint", ["$event"])
    onBeforePrint() {
        this.chart.resize(900, 600);
    }

    @HostListener("window:afterprint", ["$event"])
    onAfterPrint() {
        this.chart.resize();
    }

    @Input() data: ChartJsData | undefined = undefined;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["data"]) {
            this.createChart();
        }
    }

    ngOnInit(): void {
        if (this.data) {
            this.createChart();
        }
    }
    public chart: any;

    createChart() {
        if (!this.data) {
            return;
        }

        if (this.chart) {
            this.chart.destroy();
        }

        this.chart = new Chart("BarChart", {
            type: "bar",
            data: this.data,
            options: {
                animation:false,
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                        min: -1000,
                        max: 1000,
                    },
                },
            },
        });
    }
}
