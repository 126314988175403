import { Component, OnInit } from "@angular/core";
import { ComponentBase } from '../../models/ComponentBase';

@Component({
    selector: 'app-storage',
    templateUrl: './storage.component.html',
    styleUrls: ['./storage.component.scss', '../../style/sidebarLayout.scss']
})
export class StorageComponent extends ComponentBase implements OnInit {
    ngOnInit(): void {
    }

}
