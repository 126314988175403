import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { ComponentBase } from '../../models/ComponentBase';
import { Customer_Customer, User_UpsertUserRequest, UserRole, UserRight, UserRoleAdmin, UserRoleStorage, UserRoleSustainability, UserRoleTerminal } from '../../WebApiClient';
import { CustomerService } from '../../services/customer.service';
import { UserRoles, UserRolesAdmin, UserRolesStorage, UserRolesSustainability, UserRolesTerminal } from '../../Constants';
import { Guid } from '../../models/Guid';
import { LoginService } from '../../services/login.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-edit-user-inner',
    templateUrl: './edit-user-inner.component.html',
    styleUrls: ['./edit-user-inner.component.scss', "../../style/form-table.scss"]
})
export class EditUserInnerComponent extends ComponentBase implements OnChanges {

    public rnd: string;

    editUserFormGroup: FormGroup = new FormGroup({});
    customerIsRequiredMark: boolean = false;

    constructor(
        customerService: CustomerService,
        loginService: LoginService,
    ) {
        super();
        this.rnd = Guid.newGuid();
        let cusSub = customerService.customersObservable.subscribe(cs => {
            this.customers = cs;
        });
        this.subscriptions.push(cusSub);

        if (loginService.hasRights(UserRight.EditUserAdmin)) {
            this.roles = UserRoles;
        }
        else {
            this.roles = UserRoles.filter(ur => ur.key !== UserRole.ProjectManager);
        }

        this.roles = UserRoles;
        this.rolesAdmin = UserRolesAdmin;
        this.rolesStorage = UserRolesStorage;
        this.rolesSustainability = UserRolesSustainability;
        this.rolesTerminal = UserRolesTerminal;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.resetForm();
    }

    resetForm(): void {
        this.editUserFormGroup = new FormGroup({
            name: new FormControl('', Validators.required),
            customer: new FormControl(''),
            email: new FormControl('', [Validators.required, Validators.pattern(/^[\W\w]+@([\w-]+\.)+[\w-]{2,4}$/)]),
            username: new FormControl('', Validators.required),
            password: new FormControl(''),
            userRole: new FormControl('', Validators.required),
            userRoleAdmin: new FormControl('', Validators.required),
            userRoleStorage: new FormControl('', Validators.required),
            userRoleSustainability: new FormControl('', Validators.required),
            userRoleTerminal: new FormControl('', Validators.required),
        });

        this.bindData();
    }

    bindData(): void {
        if (this.user) {
            if (!this.newUser) {
                this.nameControl.setValue(this.user.name);
                this.customerControl.setValue(this.user.customerId);
                this.emailControl.setValue(this.user.email);
                this.usernameControl.setValue(this.user.username);
                this.userRoleControl.setValue(this.user.userRole);
                this.userRoleAdminControl.setValue(this.user.userRoleAdmin ?? "");
                this.userRoleStorageControl.setValue(this.user.userRoleStorage ?? "");
                this.userRoleSustainabilityControl.setValue(this.user.userRoleSustainability ?? "");
                this.userRoleTerminalControl.setValue(this.user.userRoleTerminal ?? "");

                this.editUserFormGroup.markAllAsTouched();
            }

            if (this.newUser) {
                this.passwordControl.setValidators(Validators.required);
                this.passwordControl.updateValueAndValidity();
            }

            let editUserFormGroupValueChangeSub = this.editUserFormGroup.valueChanges.subscribe(cs => {
                if (!this.editUserFormGroup.valid) {
                    return;
                }

                this.user.name = this.nameControl.value;
                this.user.customerId = this.customerControl.value;
                this.user.email = this.emailControl.value;
                this.user.username = this.usernameControl.value;
                this.user.userRole = this.userRoleControl.value;
                this.user.userRoleAdmin = this.userRoleAdminControl.value;
                this.user.userRoleStorage = this.userRoleStorageControl.value;
                this.user.userRoleSustainability = this.userRoleSustainabilityControl.value;
                this.user.userRoleTerminal = this.userRoleTerminalControl.value;
                this.user.password = this.passwordControl.value;
            });

            this.subscriptions.push(editUserFormGroupValueChangeSub);

            let editUserFormGroupStatusChangeSub = this.editUserFormGroup.statusChanges.subscribe(cs => {
                this.formIsValid.emit(this.editUserFormGroup.valid);
            });

            this.subscriptions.push(editUserFormGroupStatusChangeSub);

            let userRoleControlChangeSub = this.userRoleControl.valueChanges.subscribe(cs => {
                this.customerIsRequired();
            });

            this.subscriptions.push(userRoleControlChangeSub);

            let userRoleStorageControlChangeSub = this.userRoleStorageControl.valueChanges.subscribe(cs => {
                this.customerIsRequired();
            });

            this.subscriptions.push(userRoleStorageControlChangeSub);

            let userRoleSustainabilityControlChangeSub = this.userRoleSustainabilityControl.valueChanges.subscribe(cs => {
                this.customerIsRequired();
            });

            this.subscriptions.push(userRoleSustainabilityControlChangeSub);

            let userRoleTerminalControlChangeSub = this.userRoleTerminalControl.valueChanges.subscribe(cs => {
                this.customerIsRequired();
            });

            this.subscriptions.push(userRoleTerminalControlChangeSub);

            setTimeout(() => {
                this.editUserFormGroup.updateValueAndValidity();
            }, 10);
        }
    }

    customers: Customer_Customer[] | undefined;

    readonly roles: { key: UserRole, displayName: string }[];
    readonly rolesAdmin: { key: UserRoleAdmin, displayName: string }[];
    readonly rolesStorage: { key: UserRoleStorage, displayName: string }[];
    readonly rolesSustainability: { key: UserRoleSustainability, displayName: string }[];
    readonly rolesTerminal: { key: UserRoleTerminal, displayName: string }[];

    @Input()
    user: User_UpsertUserRequest = new User_UpsertUserRequest();

    @Input()
    newUser: boolean | undefined = false;

    @Output()
    formIsValid = new EventEmitter<boolean>();

    get userRole(): UserRole { return this.user.userRole; }
    set userRole(val: UserRole) {
        this.user.userRole = val;
        if (val != UserRole.Customer) {
            this.user.customerId = undefined;
        }
    }
    get nameControl(): FormControl {
        return this.getFormControl("name");
    }
    get customerControl(): FormControl {
        return this.getFormControl("customer");
    }
    get emailControl(): FormControl {
        return this.getFormControl("email");
    }
    get usernameControl(): FormControl {
        return this.getFormControl("username");
    }
    get userRoleControl(): FormControl {
        return this.getFormControl("userRole");
    }
    get userRoleAdminControl(): FormControl {
        return this.getFormControl("userRoleAdmin");
    }
    get userRoleStorageControl(): FormControl {
        return this.getFormControl("userRoleStorage");
    }
    get userRoleSustainabilityControl(): FormControl {
        return this.getFormControl("userRoleSustainability");
    }
    get userRoleTerminalControl(): FormControl {
        return this.getFormControl("userRoleTerminal");
    }
    get passwordControl(): FormControl {
        return this.getFormControl("password");
    }

    private getFormControl(name: string): FormControl {
        return this.editUserFormGroup.get(name) as FormControl;
    }

    private customerIsRequired(): void {
        if (this.userRoleControl.value === UserRole.Customer) {
            this.customerControl.setValidators(Validators.required);
            this.customerControl.updateValueAndValidity();
            this.customerIsRequiredMark = true;
            return;
        }

        if (this.userRoleStorageControl.value === UserRoleStorage.ReadCustomer || this.userRoleStorageControl.value === UserRoleStorage.EditCustomer) {
            this.customerControl.setValidators(Validators.required);
            this.customerControl.updateValueAndValidity();
            this.customerIsRequiredMark = true;
            return;
        }

        if (this.userRoleSustainabilityControl.value === UserRoleSustainability.ReadCustomer) {
            this.customerControl.setValidators(Validators.required);
            this.customerControl.updateValueAndValidity();
            this.customerIsRequiredMark = true;
            return;
        }

        if (this.userRoleTerminalControl.value === UserRoleTerminal.ReadCustomer || this.userRoleTerminalControl.value === UserRoleTerminal.EditCustomer) {
            this.customerControl.setValidators(Validators.required);
            this.customerControl.updateValueAndValidity();
            this.customerIsRequiredMark = true;
            return;
        }

        this.customerControl.setValidators([]);
        this.customerControl.updateValueAndValidity();
        this.customerIsRequiredMark = false;
    }
}
