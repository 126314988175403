<div *ngIf="mapObservable(storageItemBins, isLoading) | async">
    Loading...
</div>
<div *ngIf="mapObservable(storageItemBins, isEmpty) | async">
    No Bins yet.
</div>
<div *ngIf="mapObservable(storageItemBins, isLoaded) | async">
<table class="table table-striped table-hover">
    <colgroup>
        <col style="width: auto" />
    </colgroup>

    <thead>
        <tr>
            <th
                scope="col"
                sortable="binCode"
                [direction]="getSortDirection('binCode')"
                (sort)="onSort($event)"
            >
            Bincode
                <fa-icon
                    *ngIf="
                        getSortDirection('binCode');
                        else sortNone
                    "
                    [icon]="getSortDirectionIcon('binCode')"
                ></fa-icon>
            </th>
            <th
                scope="col"
                sortable="locationCode"
                [direction]="getSortDirection('locationCode')"
                (sort)="onSort($event)"
            >
            Location Code
                <fa-icon
                    *ngIf="
                        getSortDirection('locationCode');
                        else sortNone
                    "
                    [icon]="getSortDirectionIcon('locationCode')"
                ></fa-icon>
            </th>
            <th
                scope="col"
                scope="col"
                sortable="unitOfMeasure"
                [direction]="getSortDirection('unitOfMeasure')"
                (sort)="onSort($event)"
            >
            Unit
                <fa-icon
                    *ngIf="
                        getSortDirection('unitOfMeasure');
                        else sortNone
                    "
                    [icon]="getSortDirectionIcon('unitOfMeasure')"
                ></fa-icon>
            </th>

            <th
                scope="col"
                sortable="quantity"
                [direction]="getSortDirection('quantity')"
                (sort)="onSort($event)"
            >
            Quantity
                <fa-icon
                    *ngIf="getSortDirection('quantity'); else sortNone"
                    [icon]="getSortDirectionIcon('quantity')"
                ></fa-icon>
            </th>
        </tr>
    </thead>

    <tbody>
        <tr
            *ngFor="
                let storageItemBin of storageItemBins
                    | async
            "
            class="storageitemrow"
        >
            <td>{{ storageItemBin.binCode }}</td>
            <td>{{ storageItemBin.locationCode }}</td>
            <td>{{ storageItemBin.unitOfMeasure }}</td>
            <td>{{ storageItemBin.quantity }}</td>
        </tr>
    </tbody>
</table>
<div>
    <div class="btn-group btn-group-sm">
        <button
            class="btn btn-light"
            type="button"
            [disabled]="!(isPreviousPagePossible | async)"
            (click)="previousPage()"
        >
            &laquo; Prev
        </button>
        <button
            class="btn btn-light"
            type="button"
            [disabled]="!(isNextPagePossible | async)"
            (click)="nextPage()"
        >
            Next &raquo;
        </button>
    </div>
</div>
</div>
<ng-template #sortNone>
    <div style="width: 16px; height: 21px; display: inline-block"></div>
</ng-template>
