<div class="card" [ngClass]="{'card-collapsed':collapsed}">
    <div class="card-header" (click)="toggle()">{{headline}}
        <div class="card-header-collaps">
            <fa-icon [icon]="faMinus" *ngIf="!collapsed"></fa-icon>
            <fa-icon [icon]="faPlus" *ngIf="collapsed"></fa-icon>
        </div>
    </div>
    <div class="card-body">
        <ng-content></ng-content>
    </div>
</div>
