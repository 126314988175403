<form (ngSubmit)="update()" autocomplete="off">
    <div class="card">
        <div class="card-header">
            <h3>Edit User</h3>

        </div>
        <div class="card-body">
            <app-edit-user-inner [user]="user" (formIsValid)="formIsValid($event)"></app-edit-user-inner>

        </div>
        <div class="card-footer">
            <div class="btn-group float-right">
                <button class="btn btn-danger"
                        type="button"
                        *ngIf="showDeleteButton"
                        [disabled]="!isUserDeletable"
                        (click)="delete()">
                    Delete
                </button>
                <button class="btn btn-success"
                        type="button"
                        *ngIf="showRestoreButton"
                        (click)="restore()">
                    Restore
                </button>
                <button class="btn btn-primary"
                        [disabled]="!inputValid">
                    Update
                </button>
                <button class="btn btn-light" (click)="cancel()">
                    Cancel
                </button>
            </div>

        </div>
    </div>
</form>
