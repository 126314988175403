import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ComponentBase } from "../../models/ComponentBase";
import {
    faPlus,
    faMinus,
    IconDefinition,
} from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-card",
    templateUrl: "./card.component.html",
    styleUrls: ["./card.component.scss"],
})
export class CardComponent extends ComponentBase {
    @Input() headline: string = "";
    @Input() collapsed: boolean = false;

    @Output() opened: EventEmitter<boolean> = new EventEmitter<boolean>();

    public faPlus: IconDefinition = faPlus;
    public faMinus: IconDefinition = faMinus;

    public toggle(): void {
        this.collapsed = !this.collapsed;
        if (!this.collapsed) {
            this.opened.emit(true);
        }
    }
}
