<form (ngSubmit)="update()">
    <div class="card">
        <div class="card-header">
            <h3>Edit Customer</h3>

        </div>
        <div class="card-body">

            <app-edit-customer-inner [customer]="customer"></app-edit-customer-inner>
        </div>

        <div class="card-footer">
            <div class="btn-group float-right">
                <button class="btn btn-danger" type="button"
                        (click)="delete()">
                    Delete
                </button>
                <button class="btn btn-block btn-primary"
                        [disabled]="!isInputValid">
                    Update
                </button>
            </div>

        </div>
    </div>
</form>
