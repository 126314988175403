import { Component, Input, OnInit } from "@angular/core";
import { ComponentBase } from "../../../models/ComponentBase";
import { SortEvent } from "../../../models/ngSortable";
import {
    IconDefinition,
    faSortAmountDown,
    faSortAmountUp,
    faExternalLinkAlt,
    faDownload,
    faPlus,
    faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { StorageService } from "../../../services/storage.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
    Storage_GetStorageTankListResponse,
    Storage_StorageTank,
    Storage_StorageTankFile,
} from "../../../WebApiClient";
import { map } from "rxjs/operators";

@Component({
    selector: "app-storage-tanks",
    templateUrl: "./storage-tanks.component.html",
    styleUrls: [
        "./storage-tanks.component.scss" /*, '../../style/info-group.scss'*/,
    ],
})
export class StorageTanksComponent extends ComponentBase implements OnInit {
    public readonly response: Observable<
        Storage_GetStorageTankListResponse | undefined
    >;

    public storageTanks: Observable<Storage_StorageTank[] | undefined>;

    public sortDirection: SortEvent = {
        column: "externalId",
        direction: "asc",
    };

    public sortingBehaviorSubject = new BehaviorSubject<SortEvent>(this.sortDirection);

    public readonly isPreviousPagePossible: Observable<boolean> = of(false);
    public readonly isNextPagePossible: Observable<boolean> = of(false);

    public storageTankSelected: Storage_StorageTank | undefined;
    public storageTankFiles: Storage_StorageTankFile[] | undefined;

    public faExternalLinkAlt: IconDefinition = faExternalLinkAlt;
    public faDownload: IconDefinition = faDownload;
    public faPlus: IconDefinition = faPlus;
    public faMinus: IconDefinition = faMinus;
    public isStorageTankFilesLoading: boolean = false;
    private page: number = 1;

    @Input() storageItemId: string = "";

    constructor(private storageService: StorageService) {
        super();

        const page = this.sortingBehaviorSubject.pipe(
            map((sorting) => ({
                id: this.storageItemId,
                page: this.page,
                sort: sorting,
            }))
        );

        this.response = this.storageService.getStorageTanksObservable(page);

        this.subscribeTo(this.response, (r) => console.log("response: ", r));
        this.storageTanks = this.response.pipe(map((r) => r?.storageTanks));

        this.isNextPagePossible = this.response.pipe(
            map((r) =>
                r &&
                r.page &&
                r.pageTotal &&
                Number(r.page) < Number(r.pageTotal)
                    ? true
                    : false
            )
        );
        this.isPreviousPagePossible = this.response.pipe(
            map((r) =>
                r && r.page && r.pageTotal && Number(r.page) > 1 ? true : false
            )
        );
    }

    ngOnInit(): void {
        this.sortingBehaviorSubject.next(this.sortDirection!);
    }

    getSortDirectionIcon(column: string): IconDefinition | undefined {
        if (this.sortDirection && this.sortDirection.column === column) {
            return this.sortDirection.direction === "asc"
                ? faSortAmountDown
                : faSortAmountUp;
        }
        return undefined;
    }
    onSort({ column, direction }: SortEvent) {
        this.sortDirection = {
            column: column,
            direction: direction,
        };

        this.page = 1;

        this.sortingBehaviorSubject.next({ column, direction });
    }

    getSortDirection(column: string): any {
        if (this.sortDirection && this.sortDirection.column === column) {
            return this.sortDirection.direction;
        }
        return "";
    }

    nextPage() {
        if (!this.isNextPagePossible) return;
        this.page += 1;
        this.sortingBehaviorSubject.next(this.sortDirection!);
    }

    previousPage() {
        if (!this.isPreviousPagePossible) return;
        this.page -= 1;
        this.sortingBehaviorSubject.next(this.sortDirection!);
    }

    selectTankToggle(storageTank: Storage_StorageTank): void {
        this.storageTankFiles = undefined;
        this.isStorageTankFilesLoading = true;

        if (this.storageTankSelected === storageTank) {
            this.storageTankSelected = undefined;
        } else {
            this.storageService
                .getStorageTankFilesObectObservable(
                    this.storageItemId,
                    storageTank.id!
                )
                .pipe(map((r) => r?.storageTankFiles))
                .subscribe((response) => {
                    this.storageTankFiles = response;
                    this.isStorageTankFilesLoading = false;
                });

            this.storageTankSelected = storageTank;
        }
    }

    async downloadFile(storageTankFile: Storage_StorageTankFile) {
        await this.storageService.downloadFile(this.storageItemId, this.storageTankSelected?.id!, storageTankFile.fileName!);
    }
}
