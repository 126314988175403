import { Component, Input, OnInit } from "@angular/core";
import { ComponentBase } from "../../../models/ComponentBase";
import { SortEvent } from "../../../models/ngSortable";
import {
    IconDefinition,
    faSortAmountDown,
    faSortAmountUp,
} from "@fortawesome/free-solid-svg-icons";
import { StorageService } from "../../../services/storage.service";
import { BehaviorSubject, Observable, of } from "rxjs";
import {
    Storage_GetStorageItemMovementListResponse,
    Storage_StorageItemMovement,
} from "../../../WebApiClient";
import { map } from "rxjs/operators";

@Component({
    selector: "app-storage-item-movements",
    templateUrl: "./storage-item-movements.component.html",
    styleUrls: [
        "./storage-item-movements.component.scss" /*, '../../style/info-group.scss'*/,
    ],
})
export class StorageItemMovementsComponent
    extends ComponentBase
    implements OnInit
{
    public readonly response: Observable<
        Storage_GetStorageItemMovementListResponse | undefined
    >;

    public storageItemMovements: Observable<
        Storage_StorageItemMovement[] | undefined
    >;

    sortDirection: SortEvent = {
        column: "postingDate",
        direction: "desc",
    };

    sortingBehaviorSubject = new BehaviorSubject<SortEvent>(this.sortDirection);

    public readonly isPreviousPagePossible: Observable<boolean> = of(false);
    public readonly isNextPagePossible: Observable<boolean> = of(false);

    private page: number = 1;

    @Input() storageItemId: string = "";

    constructor(private storageService: StorageService) {
        super();

        const page = this.sortingBehaviorSubject.pipe(
            map((sorting) => ({
                id: this.storageItemId,
                page: this.page,
                sort: sorting,
            }))
        );

        this.response =
            this.storageService.getStorageItemMovementsObservable(page);

        this.subscribeTo(this.response, (r) => console.log("response: ", r));
        this.storageItemMovements = this.response.pipe(
            map((r) => r?.storageItemMovements)
        );

        this.isNextPagePossible = this.response.pipe(
            map((r) =>
                r &&
                r.page &&
                r.pageTotal &&
                Number(r.page) < Number(r.pageTotal)
                    ? true
                    : false
            )
        );
        this.isPreviousPagePossible = this.response.pipe(
            map((r) =>
                r && r.page && r.pageTotal && Number(r.page) > 1 ? true : false
            )
        );
    }

    ngOnInit(): void {
        this.sortingBehaviorSubject.next(this.sortDirection!);
    }

    getSortDirectionIcon(column: string): IconDefinition | undefined {
        if (this.sortDirection && this.sortDirection.column === column) {
            return this.sortDirection.direction === "asc"
                ? faSortAmountDown
                : faSortAmountUp;
        }
        return undefined;
    }
    onSort({ column, direction }: SortEvent) {
        this.sortDirection = {
            column: column,
            direction: direction,
        };

        this.page = 1;

        this.sortingBehaviorSubject.next({ column, direction });
    }

    getSortDirection(column: string): any {
        if (this.sortDirection && this.sortDirection.column === column) {
            return this.sortDirection.direction;
        }
        return "";
    }

    nextPage() {
        if (!this.isNextPagePossible) return;
        this.page += 1;
        this.sortingBehaviorSubject.next(this.sortDirection!);
    }

    previousPage() {
        if (!this.isPreviousPagePossible) return;
        this.page -= 1;
        this.sortingBehaviorSubject.next(this.sortDirection!);
    }
}
