
<table class="form-table" [formGroup]="editUserFormGroup">
    <tbody>
        <tr>
            <td>
                <label for="customer">Customer <span *ngIf="customerIsRequiredMark">*</span></label>
            </td>
            <td>
                <select class="form-control"
                        formControlName="customer"
                        name="customer"
                        id="customer"
                        title="Customer">
                    <option value="" disabled selected>Select Customer</option>
                    <option *ngFor="let customer of customers" [value]="customer.id">{{customer.name}}</option>
                </select>
            </td>
        </tr>
        <tr>
            <td><label for="name">Full name*</label></td>
            <td><input class="form-control" type="text" formControlName="name" placeholder="Full Name" name="name" id="name" title="Full Name" [ngClass]="{'is-invalid': (nameControl.touched || nameControl.dirty) && !nameControl.valid, 'is-valid': nameControl.valid}" /></td>
        </tr>
        <tr>
            <td><label for="email">Email*</label></td>
            <td><input class="form-control" type="email" formControlName="email" placeholder="Email" name="email" id="email" title="Email" [ngClass]="{'is-invalid': (emailControl.touched || emailControl.dirty) && !emailControl.valid, 'is-valid': emailControl.valid}" /></td>
        </tr>
        <tr>
            <td><label for="username">Username*</label></td>
            <td>
                <input class="form-control"
                       type="text"
                       formControlName="username"
                       placeholder="Username"
                       name="username"
                       autocomplete="off"
                       id="username"
                       title="Username"
                       [ngClass]="{'is-invalid': (usernameControl.touched || usernameControl.dirty) && !usernameControl.valid, 'is-valid': usernameControl.valid}" />
            </td>
        </tr>
        <tr>
            <td><label for="password">Password<span *ngIf="newUser">*</span></label></td>
            <td>
                <input class="form-control"
                       type="password"
                       formControlName="password"
                       placeholder="New password"
                       name="password"
                       id="password"
                       autocomplete="new-password"
                       [ngClass]="{'is-invalid': (passwordControl.touched || passwordControl.dirty) && !passwordControl.valid, 'is-valid': passwordControl.dirty && passwordControl.valid}"
                       title="Type a password here to change the password of the user. Leave this field blank to leave password unchanged" aria-describedby="passwordHelpBlock" />
                <small id="passwordHelpBlock" class="form-text text-muted" *ngIf="!newUser">
                    Type a password here to change the password of the user.<br />Leave this field blank to leave password unchanged
                </small>
            </td>
        </tr>
        <tr>
            <td>
                <label for="userrole">Shipping operations role*</label>
            </td>
            <td>
                <select class="form-control"
                        formControlName="userRole"
                        name="userrole"
                        id="userrole"
                        [ngClass]="{'is-invalid': (userRoleControl.touched || userRoleControl.dirty) && !userRoleControl.valid, 'is-valid': userRoleControl.valid}"
                        title="User Role">
                    <option value="" disabled selected>User Type</option>
                    <option *ngFor="let role of roles" [value]="role.key">{{role.displayName}}</option>
                </select>
            </td>
        </tr>

        <tr>
            <td><label for="userRoleAdmin">Admin role*</label></td>
            <td>
                <select class="form-control" formControlName="userRoleAdmin" name="userRoleAdmin"
                        id="userRoleAdmin"
                        [ngClass]="{'is-invalid': (userRoleAdminControl.touched || userRoleAdminControl.dirty) && !userRoleAdminControl.valid, 'is-valid': userRoleAdminControl.valid}"
                        title="Right as Admin">
                    <option value="" selected disabled hidden>Select right</option>
                    <option *ngFor="let role of rolesAdmin" [value]="role.key">{{role.displayName}}</option>
                </select>
            </td>
        </tr>
        <tr>
            <td><label for="userRoleStorage">Storage role*</label></td>
            <td>
                <select class="form-control" formControlName="userRoleStorage" name="userRoleStorage"
                        id="userRoleStorage"
                        [ngClass]="{'is-invalid': (userRoleStorageControl.touched || userRoleStorageControl.dirty) && !userRoleStorageControl.valid, 'is-valid': userRoleStorageControl.valid}"
                        title="Right as Storage">
                    <option value="" selected disabled hidden>Select right</option>
                    <option *ngFor="let role of rolesStorage" [value]="role.key">{{role.displayName}}</option>
                </select>
            </td>
        </tr>
        <tr>
            <td><label for="userRoleSustainability">Sustainability role*</label></td>
            <td>
                <select class="form-control" formControlName="userRoleSustainability" name="userRoleSustainability"
                        id="userRoleSustainability"
                        [ngClass]="{'is-invalid': (userRoleSustainabilityControl.touched || userRoleSustainabilityControl.dirty) && !userRoleSustainabilityControl.valid, 'is-valid': userRoleSustainabilityControl.valid}"
                        title="Right as Sustainability">
                    <option value="" selected disabled hidden>Select right</option>
                    <option *ngFor="let role of rolesSustainability" [value]="role.key">{{role.displayName}}</option>
                </select>
            </td>
        </tr>
        <tr>
            <td><label for="userRoleTerminal">Terminal role*</label></td>
            <td>
                <select class="form-control" formControlName="userRoleTerminal" name="userRoleTerminal"
                        id="userRoleTerminal"
                        [ngClass]="{'is-invalid':  (userRoleTerminalControl.touched || userRoleTerminalControl.dirty) && !userRoleTerminalControl.valid, 'is-valid': userRoleTerminalControl.valid}"
                        title="Right as Terminal">
                    <option value="" selected disabled hidden>Select right</option>
                    <option *ngFor="let role of rolesTerminal" [value]="role.key">{{role.displayName}}</option>
                </select>
            </td>
        </tr>
    </tbody>
</table>



<div class="form-group">
</div>

<div class="form-group">
</div>
