<app-topbar></app-topbar>

<nav class="sidebar navbar-dark bg-dark">
    <div class="sidebar-header">
        <h5>Search</h5>
    </div>
    <form [formGroup]="searchForm" (ngSubmit)="onSubmit()">
        <ul class="nav flex-column navbar-nav">
            <li class="form-group">
                <label
                    for="filterSearch"
                    style="display: flex; margin-top: 10px; margin-bottom: 0"
                >
                    <input
                        type="text"
                        class="bg-dark text-white form-control"
                        id="searchKeywordControl"
                        placeholder="Search..."
                        formControlName="searchKeyword"
                    />
                </label>
            </li>
            <li>
                <button class="btn btn-primary">Search</button>
            </li>
        </ul>
    </form>
</nav>
<ng-container>
    <div class="main-content">
        <h3 id="storageitems-header">Storage items</h3>

        <div id="storageitems-table">
            <div *ngIf="mapObservable(storageItems, isLoading) | async">
                Loading...
            </div>
            <div *ngIf="mapObservable(storageItems, isEmpty) | async">
                No Storage Items yet.
            </div>

            <div *ngIf="mapObservable(storageItems, isLoaded) | async">
                <table class="table table-striped table-hover">
                    <colgroup>
                        <col style="width: auto" />
                    </colgroup>

                    <thead>
                        <tr>
                            <th
                                scope="col"
                                sortable="description"
                                [direction]="getSortDirection('description')"
                                (sort)="onSort($event)"
                            >
                                Description
                                <fa-icon
                                    *ngIf="
                                        getSortDirectionIcon('description');
                                        else sortNone
                                    "
                                    [icon]="getSortDirectionIcon('description')"
                                ></fa-icon>
                            </th>
                            <th scope="col">Quantity</th>
                            <th
                                scope="col"
                                sortable="baseUnitOfMeasure"
                                [direction]="
                                    getSortDirection('baseUnitOfMeasure')
                                "
                                (sort)="onSort($event)"
                            >
                                Unit Of Measure
                                <fa-icon
                                    *ngIf="
                                        getSortDirectionIcon(
                                            'baseUnitOfMeasure'
                                        );
                                        else sortNone
                                    "
                                    [icon]="
                                        getSortDirectionIcon(
                                            'baseUnitOfMeasure'
                                        )
                                    "
                                ></fa-icon>
                            </th>
                            <th
                                scope="col"
                                sortable="categoryCode"
                                [direction]="getSortDirection('categoryCode')"
                                (sort)="onSort($event)"
                            >
                                Category Code
                                <fa-icon
                                    *ngIf="
                                        getSortDirectionIcon('categoryCode');
                                        else sortNone
                                    "
                                    [icon]="
                                        getSortDirectionIcon('categoryCode')
                                    "
                                ></fa-icon>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            *ngFor="let storageItem of storageItems | async"
                            class="storageitemrow" (click)="onRowClick(storageItem)"
                        >
                            <td>{{ storageItem.description }}</td>
                            <td>{{ storageItem.quantity }}</td>
                            <td>{{ storageItem.baseUnitOfMeasure }}</td>
                            <td>{{ storageItem.categoryCode }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <div class="btn-group btn-group-sm">
                    <button
                        class="btn btn-light"
                        [disabled]="!(isPreviousPagePossible | async)"
                        (click)="previousPage()"
                    >
                        &laquo; Prev
                    </button>
                    <button
                        class="btn btn-light"
                        [disabled]="!(isNextPagePossible | async)"
                        (click)="nextPage()"
                    >
                        Next &raquo;
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #sortNone>
    <div style="width: 16px; height: 21px; display: inline-block"></div>
</ng-template>
