import { UserRight } from '../WebApiClient';

export class Login {
    constructor(
        public readonly id: string,
        public readonly username: string,
        public readonly userRights: UserRight[]
    ) {

    }

}
