<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" routerLink="/">
        <img
            alt="logo"
            src="../../../assets/logo.svg"
            height="30"
            width="203"
        />
    </a>
    <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
    >
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <div class="navbar-nav">
            <a
                class="nav-item nav-link"
                routerLinkActive="active"
                routerLink="/projects"
                *ngIf="showProjects"
                >Shipping</a
            >
            <a
                class="nav-item nav-link"
                routerLinkActive="active"
                routerLink="/storage"
                *ngIf="showStorage"
                >Storage</a
            >
            <a
                class="nav-item nav-link"
                routerLinkActive="active"
                routerLink="/sustainability"
                *ngIf="showSustainability"
                >Sustainability</a
            >
            <a
                class="nav-item nav-link"
                routerLinkActive="active"
                routerLink="/terminal"
                *ngIf="showTerminal"
                >Terminal</a
            >
            <a
                class="nav-item nav-link"
                routerLinkActive="active"
                routerLink="/users"
                *ngIf="showUsers"
                >Users</a
            >
        </div>
    </div>
    <div class="navbar-nav">
        <a class="nav-link" (click)="logout()">Log out</a>
    </div>
</nav>
