<app-topbar></app-topbar>


<app-sidebar>

    <button class="btn btn-secondary btn-sm" (click)="reloadProject()">Reload</button>
</app-sidebar>


<div class="main-content">
    <router-outlet></router-outlet>
</div>
