import { Component, OnInit, OnDestroy } from '@angular/core';
import { User_UpsertUserRequest, Customer_Customer, UserRole, UserRoleAdmin, UserRoleStorage, UserRoleSustainability, UserRoleTerminal } from '../../WebApiClient';
import { Guid } from '../../models/Guid';
import { UserRoles } from '../../Constants';
import { UserService } from '../../services/user.service';
import { CustomerService } from '../../services/customer.service';
import { Subscription } from 'rxjs';
import { ToastService } from '../../services/toast.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html',
    styleUrls: ['./create-user.component.scss', '../../style/crudFloat.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {

    private readonly customersSubscription: Subscription;


    readonly roles = UserRoles;
    user: User_UpsertUserRequest;
    customers: Customer_Customer[];
    private isInputValid: boolean = false;

    constructor(
        customerService: CustomerService,
        private readonly userService: UserService,
        private readonly toastService: ToastService,
        private readonly router: Router,
    ) {
        this.customers = customerService.customers;
        this.customersSubscription = customerService.customersObservable.subscribe(c => {
            if (c)
                this.customers = c;
        });
        this.user = this.newUser();
    }

    ngOnDestroy(): void {
        this.customersSubscription.unsubscribe();
    }

    ngOnInit(): void {
    }

    get inputValid(): boolean {
        if (!this.user)
            return false;

        if (this.user.userRole == UserRole.Customer && !this.user.customerId)
            return false;

        if (!this.isInputValid) {
            return false;
        }

        return true;
    }

    private newUser(): User_UpsertUserRequest {
        const result = new User_UpsertUserRequest();
        (<any>result.userRole) = "";
        result.customerId = "";
        result.id = Guid.newGuid();
        return result;
    }


    async create() {
        if (!this.inputValid) {
            this.toastService.add("Input invalid", "The user is not valid", "warning");
            return;
        }
        try {
            if (!this.isCustomerSelectionValid)
                this.user.customerId = undefined;

            let userName = this.user.name;

            let o = this.userService.upsertUser(this.user);
            await o.toPromise();
            this.user = this.newUser();
            this.cancel();
            this.toastService.add("User created", `${userName} created`, "success");

        }
        catch (e) {
            this.toastService.reportFailure("Could not create user", e);
        }

    }

    cancel() {
        this.router.navigateByUrl("/users");
    }

    get isCustomerSelectionValid() {
        return this.user?.userRole === UserRole.Customer;
    }

    formIsValid(isvalid: boolean): void {
        this.isInputValid = isvalid;
    }
}
