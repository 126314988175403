import { Component, OnInit } from '@angular/core';
import { Customer_UpsertCustomerRequest } from '../../WebApiClient';
import { CustomerService } from '../../services/customer.service';
import { Guid } from '../../models/Guid';

@Component({
    selector: 'app-create-customer',
    templateUrl: './create-customer.component.html',
    styleUrls: ['./create-customer.component.scss', '../../style/crudFloat.scss']
})
export class CreateCustomerComponent implements OnInit {

    public customer: Customer_UpsertCustomerRequest;

    constructor(
        private readonly customerService: CustomerService
    ) {
        this.customer = this.newCustomer();
    }

    ngOnInit(): void {
    }

    create() {
        if (!this.isInputValid)
            return;
        let o = this.customerService.upsert(this.customer);
        o.toPromise().then(_ => this.customer = this.newCustomer());
        return o;
    }

    get isInputValid(): boolean {
        return this.customerService.isValid(this.customer);
    }

    private newCustomer(): Customer_UpsertCustomerRequest {
        const result = new Customer_UpsertCustomerRequest({ id: Guid.newGuid(), name: "" });
        return result;
    }
}
