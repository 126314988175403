import { UserRole, UserRoleAdmin, UserRoleStorage, UserRoleSustainability, UserRoleTerminal } from "./WebApiClient";

export const UserRoles: { key: UserRole, displayName: string }[] = [
    { key: UserRole.ProjectManager, displayName: "Project Manager" },
    { key: UserRole.GroupManager, displayName: "Group Manager" },
    { key: UserRole.Customer, displayName: "Customer Representative" }
];

export const UserRolesAdmin: { key: UserRoleAdmin, displayName: string }[] = [
    { key: UserRoleAdmin.None, displayName: "None" },
    { key: UserRoleAdmin.EditGlobal, displayName: "Edit Global" }
];

export const UserRolesStorage: { key: UserRoleStorage, displayName: string }[] = [
    { key: UserRoleStorage.None, displayName: "None" },
    { key: UserRoleStorage.ReadCustomer, displayName: "Read Customer" },
    { key: UserRoleStorage.EditCustomer, displayName: "Edit Customer" },
    { key: UserRoleStorage.EditGlobal, displayName: "Edit Global" }
];

export const UserRolesSustainability: { key: UserRoleSustainability, displayName: string }[] = [
    { key: UserRoleSustainability.None, displayName: "None" },
    { key: UserRoleSustainability.ReadCustomer, displayName: "Read Customer" },
    { key: UserRoleSustainability.ReadGlobal, displayName: "Read Global" },
    { key: UserRoleSustainability.EditGlobal, displayName: "Edit Global" },
];

export const UserRolesTerminal: { key: UserRoleTerminal, displayName: string }[] = [
    { key: UserRoleTerminal.None, displayName: "None" },
    { key: UserRoleTerminal.ReadCustomer, displayName: "Read Customer" },
    { key: UserRoleTerminal.EditCustomer, displayName: "Edit Customer" },
    { key: UserRoleTerminal.EditGlobal, displayName: "Edit Global" },
    { key: UserRoleTerminal.EditGlobalOperations, displayName: "Edit Global Operations" },
];
