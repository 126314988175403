import { Component } from "@angular/core";
import { ComponentBase } from "../../models/ComponentBase";
import {
    IconDefinition,
    faChevronCircleLeft,
    faPrint
} from "@fortawesome/free-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { StorageService } from "../../services/storage.service";
import { map, switchMap } from "rxjs/operators";
import { Storage_GetStorageItemResponse } from "../../WebApiClient";
import { ChartJsData } from "../../models/ChartJsDataset";
import { FormControl } from "@angular/forms";

type StorageItemQueryKeys = "page" | "sort" | "direction";
type StorageItemQueryParams = {
    [k in StorageItemQueryKeys]?: string;
};

@Component({
    selector: "app-storage-item-details",
    templateUrl: "./storage-item-details.component.html",
    styleUrls: [
        "./storage-item-details.component.scss",
        "../../style/sidebarLayout.scss",
    ],
})
export class StorageItemDetailsComponent extends ComponentBase {
    faBack: IconDefinition = faChevronCircleLeft;
    faPrint: IconDefinition = faPrint;

    public storageItem: Storage_GetStorageItemResponse | undefined = undefined;
    public movementsShow: boolean = false;
    public binsShow: boolean = false;
    public tanksShow: boolean = false;

    public movementChartShow: boolean = false;
    public movementYearList: number[] = [];

    public movementYearSelectorControl: FormControl = new FormControl();
    public movementYearSelected: number | undefined = undefined;

    private initCall: boolean = true;

    public chartJsData: ChartJsData = {
        labels: [],
        datasets: [],
    };

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        private storageService: StorageService,
        private readonly router: Router
    ) {
        super();
        let storageItemSubscription = activatedRoute.params
            .pipe(
                map((params) => params["id"]),
                switchMap((id) => storageService.getStorageItemObservable(id))
            )
            .subscribe((storageItem) => {
                this.storageItem = storageItem;
                if (storageItem && storageItem.movementYears) {
                    this.movementYearSelected = storageItem.movementYears[0];
                }
            });

        this.subscriptions.push(storageItemSubscription);

        let movementYearSelectorSubscription =
            this.movementYearSelectorControl.valueChanges.subscribe((year) => {
                this.movementYearSelected = year;
                this.bindMovementChart();
            });

        this.subscriptions.push(movementYearSelectorSubscription);
    }

    movementChartOpened($event: any) {
        if (
            !this.storageItem ||
            !this.storageItem.id ||
            !this.movementYearSelected
        ) {
            return;
        }

        if (this.initCall) {
            this.movementYearSelectorControl.setValue(
                this.movementYearSelected
            );
        }

        this.bindMovementChart();
    }

    movementsOpened($event: any) {
        if (!this.storageItem || !this.storageItem.id) {
            return;
        }

        this.movementsShow = true;
    }

    binsOpened($event: any) {
        if (!this.storageItem || !this.storageItem.id) {
            return;
        }

        this.binsShow = true;
    }

    tanksOpened($event: any) {
        if (!this.storageItem || !this.storageItem.id) {
            return;
        }

        this.tanksShow = true;
    }

    back() {
        const qp2 = {
            ...this.activatedRoute.snapshot.queryParams,
        } as StorageItemQueryParams;

        this.router.navigate(["/storage/items"], {
            queryParams: qp2,
            relativeTo: this.activatedRoute,
            queryParamsHandling: "",
        });
    }

    triggerprint() {
        setTimeout(() => {
            window.print();
        }, 1000);

        this.movementsOpened(true);
        this.movementChartOpened(true);
        this.binsOpened(true);
        this.tanksOpened(true);
    }

    private bindMovementChart(): void {
        if (
            !this.storageItem ||
            !this.storageItem.id ||
            !this.movementYearSelected
        ) {
            return;
        }

        if (this.initCall) {
            this.initCall = false;
            return;
        }

        this.storageService
            .getStorageItemMovementQuantityObservable(
                this.storageItem.id,
                this.movementYearSelected
            )
            .subscribe((data) => {
                if (!data) {
                    return;
                }
                this.chartJsData = {
                    labels: data.map((d) => d.monthName ?? ""),
                    datasets: [
                        {
                            label: "Added",
                            data: data.map((d) => d.added ?? 0),
                            backgroundColor: "rgb(75, 192, 192)",
                            borderColor: "rgba(75, 192, 192, 1)",
                            borderWidth: 1,
                        },
                        {
                            label: "Removed",
                            data: data.map((d) => d.removed ?? 0),
                            backgroundColor: "rgba(255, 99, 132)",
                            borderColor: "rgba(255, 99, 132, 1)",
                            borderWidth: 1,
                        },
                    ],
                };
                this.movementChartShow = true;
            });
    }
}
