<app-topbar></app-topbar>

<nav class="sidebar navbar-dark bg-dark">
    <div class="sidebar-header">
        <h5>Search</h5>
    </div>

    <form [formGroup]="searchForm">
        <ul class="nav flex-column navbar-nav">
            <li>
                <input type="checkbox"
                       [checked]="showActive | async"
                       (click)="setShowActive($event.target.checked)"
                       id="chkShowActive" />
                <label for="chkShowActive">Show Active</label>
            </li>
            <li>
                <input type="checkbox"
                       [checked]="showInactive | async"
                       (click)="setShowInactive($event.target.checked)"
                       id="chkShowInactive" />
                <label for="chkShowInactive">Show Inactive</label>
            </li>
            <li class="form-group">
                <label for="filterSearch" style="display:flex; margin-top: 10px; margin-bottom: 0">
                    <input type="text" class="bg-dark text-white form-control" id="searchKeywordControl" placeholder="Search..." [formControl]="searchKeywordControl">
                </label>
            </li>
            <li class="form-group">
                <select class="bg-dark text-white form-control" id="searchUserRoleControl" [formControl]="searchUserRoleControl">
                    <option value="">All Roles</option>
                    <option *ngFor="let role of roles" [value]="role.key">{{role.displayName}}</option>
                </select>
            </li>
            <li class="form-group">
                <select class="bg-dark text-white form-control" id="searchCustomerControl" [formControl]="searchCustomerControl">
                    <option value="">All customers</option>
                    <option *ngFor="let customer of customers" [value]="customer.id">{{customer.name}}</option>
                </select>
            </li>
        </ul>
    </form>
</nav>
<ng-container *ngIf="{e: selectedUser | async, n: showNewUser | async} as editor">
    <div class="main-content" [ngClass]="{'editor-show': editor.e || editor.n}">
        <h3 id="users-header">Users</h3>

        <div id="users-table">

            <div *ngIf="mapObservable(users, isLoading) | async">Loading...</div>
            <div *ngIf="mapObservable(users, isEmpty) | async">No users yet.</div>

            <div *ngIf="mapObservable(users, isLoaded) | async">
                <table class="table table-striped">
                    <colgroup>
                        <col style="width:auto;" />
                        <col style="width:auto;" />
                        <col style="width:auto;" />
                        <col style="width:auto;" />
                        <col style="width:auto;" />
                        <col style="width:80px;" *ngIf="showEditButtons" />
                    </colgroup>

                    <thead>
                        <tr>
                            <th scope="col" sortable="name" [direction]="getSortDirection('name')" (sort)="onSort($event)">Name <fa-icon *ngIf="getSortDirectionIcon('name'); else sortNone" [icon]="getSortDirectionIcon('name')"></fa-icon></th>
                            <th scope="col" sortable="email" [direction]="getSortDirection('email')" (sort)="onSort($event)">Email <fa-icon *ngIf="getSortDirectionIcon('email'); else sortNone" [icon]="getSortDirectionIcon('email')"></fa-icon></th>
                            <th scope="col" sortable="userRole" [direction]="getSortDirection('userRole')" (sort)="onSort($event)">Role <fa-icon *ngIf="getSortDirectionIcon('userRole'); else sortNone" [icon]="getSortDirectionIcon('userRole')"></fa-icon></th>
                            <th scope="col" sortable="customer" [direction]="getSortDirection('customer')" (sort)="onSort($event)">Customer <fa-icon *ngIf="getSortDirectionIcon('customer'); else sortNone" [icon]="getSortDirectionIcon('customer')"></fa-icon></th>
                            <th scope="col" sortable="lastLogin" [direction]="getSortDirection('lastLogin')" (sort)="onSort($event)">Last Login <fa-icon *ngIf="getSortDirectionIcon('lastLogin'); else sortNone" [icon]="getSortDirectionIcon('lastLogin')"></fa-icon></th>
                            <th scope="col" *ngIf="showEditButtons"><!--Edit--></th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let user of users | async"
                            class="user"
                            [ngClass]="{'inactive': !isActive(user)}">

                            <td class="name">{{user.name}}</td>
                            <td class="email">{{user.email}}</td>
                            <td class="role">{{roleName(user)}}</td>
                            <td class="customer-name">{{user.customerName}}</td>
                            <td class="last-login"
                                *ngIf="user.lastLogin"
                                [title]="user.lastLogin | date: 'medium'">
                                {{user.lastLogin | date: 'mediumDate' }}
                            </td>
                            <td *ngIf="!user.lastLogin"
                                class="last-login">
                                unknown
                            </td>
                            <td *ngIf="showEditButtons">
                                <button class="btn btn-sm btn-secondary float-right"
                                        [ngClass]="{'active': user === selectedUser}"
                                        (click)="editUser(user.id)">
                                    Edit
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div style="display:flex;">

                <div class="btn-group btn-group-sm">
                    <button class="btn btn-light" [disabled]="!isPreviousPagePossible" (click)="previousPage()">&laquo; Prev</button>
                    <button class="btn btn-light" [disabled]="!isNextPagePossible" (click)="nextPage()">Next &raquo;</button>
                </div>
                <button class="btn btn-primary btn-sm"
                        (click)="startNewUser()"
                        [ngClass]="{active: showNewUser}"
                        *ngIf="showEditButtons">
                    Create new User...
                </button>
            </div>
        </div>
        <div id="user-editor">
            <div *ngIf="selectedUser |async" class="user-details">
                <app-edit-user [user]="selectedUser | async"></app-edit-user>
            </div>
            <div *ngIf="showNewUser | async" class="user-details">
                <app-create-user></app-create-user>

            </div>
        </div>
    </div>
</ng-container>
<ng-template #sortNone>
    <div style="width: 16px; height: 21px; display: inline-block"></div>
</ng-template>
