<app-topbar></app-topbar>

<nav class="sidebar navbar-dark bg-dark d-print-none">
    <div class="sidebar-header"></div>
    <ul class="nav flex-column navbar-nav">
        <li>
            <button class="btn btn-outline-primary btn-block" (click)="back()">
                <fa-icon [icon]="faBack"></fa-icon>
                <span style="padding-left: 10px">Back</span>
            </button>
        </li>
    </ul>
</nav>
<ng-container>
    <div class="main-content">
        <div class="main-content-header">
        <h3 id="storageitemdetails-header">Storage item details</h3>
        <a href="javascript:;" class="btn btn-link printlink" (click)="triggerprint()"><fa-icon [icon]="faPrint"></fa-icon></a>
    </div>
        <app-card headline="Storage item">
            <table
                class="table table-item-details table-hover"
                *ngIf="storageItem"
            >
                <tbody>
                    <tr>
                        <td>Description</td>
                        <td>{{ storageItem.description }}</td>
                    </tr>
                    <tr>
                        <td>Quantity</td>
                        <td>{{ storageItem.quantity }}</td>
                    </tr>
                    <tr>
                        <td>ExternalId</td>
                        <td>{{ storageItem.externalId }}</td>
                    </tr>
                    <tr>
                        <td>Unit of measure</td>
                        <td>{{ storageItem.baseUnitOfMeasure }}</td>
                    </tr>
                    <tr>
                        <td>CO2/unit</td>
                        <td>{{ storageItem.cO2PerUnit }}</td>
                    </tr>
                    <tr>
                        <td>Category code</td>
                        <td>{{ storageItem.categoryCode }}</td>
                    </tr>
                </tbody>
            </table>
        </app-card>

        <app-card
            headline="Movement chart"
            collapsed="true"
            (opened)="movementChartOpened($event)"
        >
            <div *ngIf="storageItem && storageItem.movementYears && storageItem.movementYears.length > 0; else movementChartEmpty">
                <div class="form-group year-selector" *ngIf="storageItem">
                    <label for="movement-year-selector">Select year</label>
                    <select
                        id="movement-year-selector"
                        class="form-control"
                        [formControl]="movementYearSelectorControl"
                    >
                        <option value="" disabled selected>Select year</option>
                        <option
                            value="{{ year }}"
                            *ngFor="let year of storageItem.movementYears"
                        >
                            {{ year }}
                        </option>
                    </select>
                </div>

                <div class="chart-container">
                    <app-bar-chart [data]="chartJsData" *ngIf="movementChartShow"></app-bar-chart>
                </div>
            </div>
            <ng-template #movementChartEmpty>
                <div>No Movements yet.</div>
            </ng-template>
        </app-card>

        <app-card
            headline="Movements"
            collapsed="true"
            (opened)="movementsOpened($event)"
        >
            <div *ngIf="movementsShow && storageItem && storageItem.id">
                <app-storage-item-movements
                    [storageItemId]="storageItem.id"
                ></app-storage-item-movements>
            </div>
        </app-card>

        <app-card
            headline="Bins"
            collapsed="true"
            (opened)="binsOpened($event)"
        >
            <div *ngIf="binsShow && storageItem && storageItem.id">
                <app-storage-item-bins
                    [storageItemId]="storageItem.id"
                ></app-storage-item-bins>
            </div>
        </app-card>

        <app-card headline="Tanks" collapsed="true" (opened)="tanksOpened($event)">
            <div *ngIf="tanksShow && storageItem && storageItem.id">
                <app-storage-tanks
                    [storageItemId]="storageItem.id"
                ></app-storage-tanks>
            </div>
        </app-card>
    </div>
</ng-container>
